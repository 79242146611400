exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-canjearqr-js": () => import("./../../../src/pages/canjearqr.js" /* webpackChunkName: "component---src-pages-canjearqr-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-datazos-index-js": () => import("./../../../src/pages/datazos/index.js" /* webpackChunkName: "component---src-pages-datazos-index-js" */),
  "component---src-pages-eventos-index-js": () => import("./../../../src/pages/eventos/index.js" /* webpackChunkName: "component---src-pages-eventos-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-imperial-recorre-argentina-index-js": () => import("./../../../src/pages/imperial-recorre-argentina/index.js" /* webpackChunkName: "component---src-pages-imperial-recorre-argentina-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mi-cuenta-index-js": () => import("./../../../src/pages/mi-cuenta/index.js" /* webpackChunkName: "component---src-pages-mi-cuenta-index-js" */),
  "component---src-pages-mi-cuenta-recompensas-js": () => import("./../../../src/pages/mi-cuenta/recompensas.js" /* webpackChunkName: "component---src-pages-mi-cuenta-recompensas-js" */),
  "component---src-pages-recetas-index-js": () => import("./../../../src/pages/recetas/index.js" /* webpackChunkName: "component---src-pages-recetas-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-terminos-condiciones-index-js": () => import("./../../../src/pages/terminos-condiciones/index.js" /* webpackChunkName: "component---src-pages-terminos-condiciones-index-js" */),
  "component---src-pages-variedad-index-js": () => import("./../../../src/pages/variedad/index.js" /* webpackChunkName: "component---src-pages-variedad-index-js" */),
  "component---src-templates-datazo-single-js": () => import("./../../../src/templates/datazo-single.js" /* webpackChunkName: "component---src-templates-datazo-single-js" */),
  "component---src-templates-recetas-single-js": () => import("./../../../src/templates/recetas-single.js" /* webpackChunkName: "component---src-templates-recetas-single-js" */),
  "component---src-templates-variedades-js": () => import("./../../../src/templates/variedades.js" /* webpackChunkName: "component---src-templates-variedades-js" */)
}

