import React, { createContext, useState, useEffect } from 'react';
import { navigate } from "gatsby";
import jwtDecode from "jwt-decode";


// Crear un contexto con un valor predeterminado
export const AuthContext = createContext({
  auth: { user: null, token: null, loading: false },
  setAuth: () => {},
  logout: () => {},
});

const EXPIRATION_WINDOW_SECONDS = 280;

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("authToken");
      const user = JSON.parse(localStorage.getItem("user"));
      return token ? { user, token, loading: false } : { user: null, token: null, loading: false };
    }
    return { user: null, token: null, loading: false };
  });

  const logout = () => {
    // limpia el estado de autenticación
    setAuth({ user: null, token: null, loading: false });
    // elimina el token del almacenamiento local
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    navigate("/");
  };

  const fetchNewToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
        console.error("No refresh token available");
        return;
    }

    const response = await fetch('https://backdatazoimperial.site/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `
                mutation RefreshAuthToken {
                    refreshJwtAuthToken(
                        input: {
                            jwtRefreshToken: "${refreshToken}"
                    }) {
                        authToken
                    }
                }
            `
        }),
    });

    const responseData = await response.json();
    const newAuthToken = responseData?.data?.refreshJwtAuthToken?.authToken;

    if (newAuthToken) {
        localStorage.setItem('authToken', newAuthToken);
        setAuth({ ...auth, token: newAuthToken });
    } else {
        console.error("Failed to refresh auth token");
    }
  };

  const scheduleTokenRefresh = (expirationTime) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const refreshTime = expirationTime - currentTime - 60; // refrescar el token 60 segundos antes de que expire
    setTimeout(fetchNewToken, refreshTime * 1000);
  };

  const getExpirationTimeFromToken = (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp; // 'exp' es un campo común que contiene la marca de tiempo de expiración en un token JWT
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));
      if (token && user) {
        setAuth({ loading: false, user, token });

        const expirationTime = getExpirationTimeFromToken(token);
        scheduleTokenRefresh(expirationTime);
      } else {
        setAuth({ loading: false, user: null, token: null });
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
