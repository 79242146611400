import React from 'react';
import { AuthProvider } from './src/context/AuthContext';

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
import ReactModal from 'react-modal';

ReactModal.setAppElement('#___gatsby'); // Gatsby mount point


export const wrapRootElement = ({ element }) => {
    return (
      <AuthProvider>
        {element}
      </AuthProvider>
    );
};